import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import UserProfile from './components/UserProfile';
import Login from './components/Login';
import Registration from './components/Registration';
import ForgotPassword from './components/ForgotPassword';
import ResetPasswordForm from './components/ResetPassword';
import AccountActivation from './pages/AccountActivation';
import { AuthProvider, useAuth } from './components/AuthContext';
import Box from '@mui/material/Box';
import theme from './theme'; // Adjust this import according to where your theme is defined

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

function AppContent() {
  const { authStatus } = useAuth();

  return (
    <Routes>
      {authStatus ? (
        <>
          <Route path="/" element={<Navigate replace to="/registration" />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
          <Route path="/activate/:activationId/:activationToken" element={<AccountActivation />} />
          <Route path="*" element={<Navigate replace to="/profile" />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Navigate replace to="/registration" />} />

          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
          <Route path="/activate/:activationId/:activationToken" element={<AccountActivation />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      )}
    </Routes>
  );
}

export default App;
