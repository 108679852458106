import React, { useState, useContext } from 'react';
import {
  AppBar, Toolbar, Button, Menu, MenuItem,  ThemeProvider, CssBaseline, TextField, Box, Typography, Container, CircularProgress, Alert, Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../logo.png'; // Ensure correct path
import { createTheme } from '@mui/material/styles';
import { AuthContext } from './AuthContext'; // Ensure correct path
import Footer from '../pages/Footer'; // Ensure correct path to the Footer component
import './styles.css'; // Import the CSS file

const theme = createTheme();

export default function LoginForm() {
  const [fields, setFields] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const { updateAuthStatus } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContactClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleContactClose = () => {
    setAnchorEl(null);
  };

  const validate = () => {
    const newErrors = {};
    if (!fields.email) newErrors.email = 'Email is required.';
    if (!fields.password) newErrors.password = 'Password is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;
    setIsSubmitting(true);
    setIsError(false);

    try {
      const response = await axios.post('https://ngat.ethernet.edu.et/api/login', fields);
      console.log(response.data);

      localStorage.setItem('authToken', response.data.token); // Store the token
      updateAuthStatus(true); // Update authentication status in context

      navigate('/profile'); // Navigate to profile page after successful login
    } catch (error) {
      console.error('Login error:', error.response || error);
      setIsError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: 'rgb(18,82,166)',
      },
      background: {
        default: '#f5f5f5',
      },
    },
    typography: {
      fontFamily: '"Times New Roman", Times, serif',
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className="page-container">
        <CssBaseline />
        <AppBar position="static">
          <Toolbar>
            <img src={logo} alt="Ministry of Education Logo" style={{ maxHeight: '50px', marginRight: '10px' }} />
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Ministry of Education
            </Typography>
            <Button color="inherit" onClick={() => navigate('/')}>Home</Button>
            <Button color="inherit" onClick={() => navigate('/login')}>About</Button>
            <Button color="inherit" onClick={handleContactClick}>Contact</Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleContactClose}
      >
        <MenuItem onClick={handleContactClose}>
          <a href="https://t.me/MoENGAT" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Telegram</a>
        </MenuItem>
        <MenuItem onClick={handleContactClose}>
          <a href="https://chat.whatsapp.com/JSQtXWXvoYqLHj4gFvpouN" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>WhatsApp</a>
        </MenuItem>
      </Menu>            <Button color="inherit" onClick={() => navigate('/registration')}>Register</Button>
          </Toolbar>
        </AppBar>
        <Container component="main" sx={{ maxWidth: 'sm', flex: 1 }}>
          <CssBaseline />
          <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={fields.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={fields.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'Login'}
              </Button>
              {isError && (
                <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                  Login failed. Please check your credentials and try again.
                </Alert>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
              <Link href="#" variant="body2" onClick={() => navigate('/forgotpassword')}>
                  {"Forgot Password ?"}
                </Link>
                <Link href="#" variant="body2" onClick={() => navigate('/registration')}>
                  {"Don't have an account? Register here"}
                </Link>
              </Box>
            </Box>
          </Box>
        </Container>
        <Footer />
      </div>
    </ThemeProvider>
  );
}
