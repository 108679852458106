import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Container, Typography, CircularProgress, Box, Alert, AppBar, Toolbar, Button, CssBaseline } from '@mui/material';
import logo from '../logo.png'; // Ensure correct path
import Footer from './Footer'; // Ensure correct path to the Footer component
import './styles.css'; // Import the CSS file

const AccountActivation = () => {
    const { activationId, activationToken } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                navigate('/login');
            }, 5000); // Redirect after 5 seconds
        }
    }, [showSuccess, navigate]);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const activateAccount = async () => {
        if (!password || !confirmPassword) {
            setError('Please fill in all password fields.');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        setLoading(true); // Start loading
        try {
            const response = await axios.post(`https://ngat.ethernet.edu.et/api/activate/${activationId}/${activationToken}`, { password });
            setShowSuccess(true); // Show success message
            setError(''); // Clear previous errors
        } catch (error) {
            setError(error.response.data.message || 'Failed to activate account');
            setShowSuccess(false);
        } finally {
            setLoading(false); // Stop loading regardless of the outcome
        }
    };

    return (
        <div className="page-container">
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <img src={logo} alt="Ministry of Education Logo" style={{ maxHeight: '50px', marginRight: '10px' }} />
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Ministry of Education
                    </Typography>
                    <Button color="inherit" onClick={() => navigate('/')}>Home</Button>
                    <Button color="inherit" onClick={() => navigate('/about')}>About</Button>
                    <Button color="inherit" onClick={() => navigate('/contact')}>Contact</Button>
                    <Button color="inherit" onClick={() => navigate('/login')}>Login</Button>

                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="xs" sx={{ mt: 8, flexGrow: 1 }}>
                <Typography component="h1" variant="h5" textAlign="center">
                    Set Your Password
                </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="New Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={handlePasswordChange}
                    value={password}
                    disabled={loading}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                    onChange={handleConfirmPasswordChange}
                    value={confirmPassword}
                    disabled={loading}
                />
                {error && <Typography color="error">{error}</Typography>}
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={activateAccount}
                    disabled={loading}
                >
                    {loading ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CircularProgress size={24} sx={{ color: 'white', mr: 1 }} />
                            Creating an account...
                        </Box>
                    ) : 'Activate Account'}
                </Button>
                {showSuccess && (
                    <Alert severity="success" sx={{ mt: 2 }}>
                        Account successfully created! Redirecting to login...
                    </Alert>
                )}
            </Container>
            <Footer />
        </div>
    );
};

export default AccountActivation;
