import React, { useState } from 'react';
import {
  AppBar, Toolbar, Button, ThemeProvider, CssBaseline, TextField, Box, Typography, Container, CircularProgress, Alert, Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../logo.png'; // Ensure correct path
import { createTheme } from '@mui/material/styles';
import Footer from '../pages/Footer'; // Ensure correct path to the Footer component
import './styles.css'; // Import the CSS file

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: 'rgb(18,82,166)',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: '"Times New Roman", Times, serif',
  },
});

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ open: false, message: '', severity: 'info' });
  const [isDisabled, setIsDisabled] = useState(false); // New state to control disabling of fields
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setEmailError('');
    setIsDisabled(true); // Disable fields on submission

    try {
      const response = await axios.post('https://ngat.ethernet.edu.et/api/password-reset-request', { email });

      if (response.status === 200) {
        setAlertMessage({ open: true, message: 'Password reset link has been successfully sent to your email. Please follow the instructions there.', severity: 'success' });
      } else if (response.status === 404) {
        setEmailError('The provided email address was not found.');
      } else {
        setAlertMessage({ open: true, message: 'Failed to send password reset link. Please try again later.', severity: 'error' });
      }
    } catch (error) {
      console.error('Password reset error:', error.response || error);
      if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.email) {
        setEmailError('The provided email address was not found. Make sure you\'ve registered or activated your account.');
      } else {
        setAlertMessage({ open: true, message: 'Failed to send password reset link. Please try again later.', severity: 'error' });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setAlertMessage({ ...alertMessage, open: false });
    if (alertMessage.severity === 'success') {
      navigate('/login'); // Redirect to login if successful
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className="page-container">
        <CssBaseline />
        <AppBar position="static">
          <Toolbar>
            <img src={logo} alt="Ministry of Education Logo" style={{ maxHeight: '50px', marginRight: '10px' }} />
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Ministry of Education
            </Typography>
            <Button color="inherit" onClick={() => navigate('/')}>Home</Button>
            <Button color="inherit" onClick={() => navigate('/about')}>About</Button>
            <Button color="inherit" onClick={() => navigate('/contact')}>Contact</Button>
            <Button color="inherit" onClick={() => navigate('/registration')}>Register</Button>
            <Button color="inherit" onClick={() => navigate('/login')}>Login</Button>
          </Toolbar>
        </AppBar>
        <Container component="main" sx={{ maxWidth: 'sm', flex: 1 }}>
          <CssBaseline />
          <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography component="h1" variant="h5">
              Forget Password
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
                disabled={isDisabled} // Disable field
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                disabled={isSubmitting || isDisabled} // Disable button
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'Send Password Reset Link'}
              </Button>
              {alertMessage.open && (
                <Alert severity={alertMessage.severity} sx={{ width: '100%', mt: 2 }} onClose={handleCloseSnackbar}>
                  {alertMessage.message}
                </Alert>
              )}


              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                <Link variant="body2" onClick={() => navigate('/login')} style={{ cursor: 'pointer' }} disabled={isDisabled}>
                  Back to login
                </Link>
                

              </Box>
            </Box>
          </Box>
        </Container>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default ForgetPassword;
