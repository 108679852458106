import React, { useState, useEffect } from 'react';
import {
  AppBar, Toolbar, Button, Menu, ThemeProvider, CssBaseline, TextField, FormControlLabel, Checkbox,
  Grid, Box, Typography, Container, MenuItem, FormControl, InputLabel, Select, FormHelperText, Card, CardContent, Stepper, Step, LinearProgress, StepLabel,
  List, ListItem, ListItemText, ListItemIcon, Alert, CircularProgress, Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SchoolIcon from '@mui/icons-material/School';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GenderIcon from '@mui/icons-material/Wc'; // Gender icon
import logo from '../logo.png'; // Ensure correct path
import { createTheme } from '@mui/material/styles';
import './styles.css'; // Import the CSS file
import Footer from '../pages/Footer'; // Ensure correct path to the Footer component
import Countdown from 'react-countdown';
import styled from 'styled-components'; // Or another CSS-in-JS library

const theme = createTheme();

const isValidName = (name) => /^[a-zA-Z\s'/-]+$/.test(name);
const isValidEmail = (email) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
const isValidPhone = (phone) => /^[0-9]\d{0,9}$/.test(phone);

export default function RegistrationForm() {
  const [submitError, setSubmitError] = useState(null);

  const [fields, setFields] = useState({
    firstName: '',
    fatherName: '',
    grandFatherName: '',
    email: '',
    phoneNumber: '',
    file: null,
    bsccgpa: '',
    masterscgpa: '',
    gender: '',
    consent: false,
    speciality: '',
    institutionBsc: '',
    customInstitutionBsc: '',
    institutionMasters: '',
    customInstitutionMasters: '',
    examcenter: '', // Add examcenter to state
    bscProgramName: '', // Add BSc Program Name
    phdProgramName: '', // Add PhD Program Name
  });
  const [errors, setErrors] = useState({});
  const [selectedProgram, setSelectedProgram] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [examCenters, setExamCenters] = useState([]); // Add state for exam centers
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const steps = ['Personal Information', 'Educational Background', 'Review & Submit'];

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleContactClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleContactClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    axios.get('https://ngat.ethernet.edu.et/api/signup-inist')
      .then(response => setInstitutions(response.data))
      .catch(error => console.error('Error fetching institutions:', error));

    axios.get('https://ngat.ethernet.edu.et/api/exam-centers')
      .then(response => setExamCenters(response.data))
      .catch(error => console.error('Error fetching exam centers:', error));
  }, []);

  const handleNext = () => {
    if (!validateCurrentStep()) {
      setIsSuccess(false);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
    validateField(name, value, files);
  };

  const handleProgramChange = (event) => {
    setSelectedProgram(event.target.value);
    console.log("Program selected:", event.target.value);  // Log the selected program
  };
  const validateCurrentStep = () => {
    const newErrors = {};
    switch (activeStep) {
      case 0:
        if (!fields.firstName || !isValidName(fields.firstName)) newErrors.firstName = 'Invalid first name.';
        if (!fields.fatherName || !isValidName(fields.fatherName)) newErrors.fatherName = 'Invalid father name.';
        if (!fields.grandFatherName || !isValidName(fields.grandFatherName)) newErrors.grandFatherName = 'Invalid grand father name.';
        if (!fields.email || !isValidEmail(fields.email)) newErrors.email = 'Invalid email.';
        if (!fields.phoneNumber || !isValidPhone(fields.phoneNumber)) newErrors.phoneNumber = 'Invalid phone number.';
        if (!fields.gender) newErrors.gender = 'Gender is required.';
        if (!fields.file) newErrors.file = 'File upload is required.';
        break;
      case 1:
        if (!selectedProgram) newErrors.program = 'Selecting a program is required.';
        if (!fields.speciality) newErrors.speciality = 'Speciality is required.';
        if (selectedProgram === 'Masters') {
          if (!fields.bsccgpa || isNaN(fields.bsccgpa)) newErrors.bsccgpa = 'Valid BSc CGPA is required.';
          if (!fields.bscProgramName) newErrors.bscProgramName = 'BSc Program Name is required.';
        }
        if (selectedProgram === 'PhD') {
          if (!fields.bsccgpa || isNaN(fields.bsccgpa)) newErrors.bsccgpa = 'Valid BSc CGPA is required.';
          if (!fields.masterscgpa || isNaN(fields.masterscgpa)) newErrors.masterscgpa = 'Valid Masters CGPA is required.';
          if (!fields.bscProgramName) newErrors.bscProgramName = 'BSc Program Name is required.';
          if (!fields.phdProgramName) newErrors.phdProgramName = 'PhD Program Name is required.';
        }
        if (!fields.institutionBsc) newErrors.institutionBsc = 'BSc institution is required.';
        if (fields.institutionBsc === 'Other' && !fields.customInstitutionBsc) newErrors.customInstitutionBsc = 'BSc institution name is required.';
        if (selectedProgram === 'PhD' && !fields.institutionMasters) newErrors.institutionMasters = 'Masters institution is required.';
        if (fields.institutionMasters === 'Other' && !fields.customInstitutionMasters) newErrors.customInstitutionMasters = 'Masters institution name is required.';
        if (!fields.examcenter) newErrors.examcenter = 'Exam center is required.';
        break;
      case 2:
        if (!fields.consent) newErrors.consent = 'You must agree to the terms to proceed.';
        break;
      default:
        break;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const validateField = (name, value, files = null) => {
    let errorMsg = '';
    switch (name) {
      case 'firstName':
      case 'fatherName':
      case 'grandFatherName':
        errorMsg = !value ? 'This field is required' : (!isValidName(value) ? 'Invalid name. Only letters and spaces are allowed' : '');
        break;
      case 'email':
        errorMsg = !value ? 'This field is required' : (!isValidEmail(value) ? 'Invalid email format' : '');
        break;
      case 'phoneNumber':
        errorMsg = !value ? 'This field is required' : (!isValidPhone(value) ? 'Invalid phone number. Must contain only numbers and 10 digits ( eg. 09 11 ** ** ** **)' : '');
        break;
      case 'file':
        errorMsg = !files ? 'Profile picture is required' : '';
        break;
      case 'bsccgpa':
      case 'masterscgpa':
        if (!value) {
          errorMsg = 'This field is required';
        } else if (isNaN(value)) {
          errorMsg = 'CGPA must be a number';
        } else if (value > 4 || value <= 0) {
          errorMsg = 'CGPA should not be be less than 1 or greater than 4';
        } else {
          errorMsg = '';
        }
        break;
      case 'gender':
        errorMsg = !value ? 'Gender is required' : '';
        break;
      case 'consent':
        errorMsg = !value ? 'You must agree to the terms' : '';
        break;
      case 'speciality':
        errorMsg = !value ? 'Speciality is required' : '';
        break;
      case 'institutionBsc':
        errorMsg = !value ? 'BSc institution is required' : '';
        break;
      case 'customInstitutionBsc':
        errorMsg = !value ? 'BSc institution name is required' : '';
        break;
      case 'institutionMasters':
        errorMsg = !value ? 'Masters institution is required' : '';
        break;
      case 'customInstitutionMasters':
        errorMsg = !value ? 'Masters institution name is required' : '';
        break;
      case 'examcenter':
        errorMsg = !value ? 'Exam center is required' : '';
        break;
      case 'bscProgramName':
        errorMsg = !value ? 'BSc Program Name is required' : '';
        break;
      case 'phdProgramName':
        errorMsg = !value ? 'PhD Program Name is required' : '';
        break;
      default:
        break;
    }
    setErrors(prev => ({ ...prev, [name]: errorMsg }));
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUploading(true); // Start uploading
    setIsSubmitting(true); // Start submitting
    setSubmitError(null); // Clear previous error messages

    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value instanceof Blob ? value : String(value));
    });

    if (selectedProgram) {
      formData.append('program', selectedProgram);
    }

    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    try {
      const response = await axios.post('https://ngat.ethernet.edu.et/api/register', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      console.log(response.data);
      setIsSuccess(true);

      setTimeout(() => {
        navigate('/login');
      }, 5000);



    } catch (error) {
      console.error('Submission error:', error.response || error);
      setSubmitError(error.response?.data?.message || 'An error occurred. Please try again.');
      setIsSuccess(false);
    } finally {
      setIsUploading(false); // End uploading
      setIsSubmitting(false); // End submitting
      setUploadProgress(0); // Reset progress
    }
  };

  const defaultTheme = createTheme({
    components: {
      // Style overrides for TextField
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              height: '20px', // Adjust the height as needed
            },
          },
        },
      },
      // Style overrides for Select
      MuiSelect: {
        styleOverrides: {
          select: {
            height: '20px', // Adjust the height as needed
          },
        },
      },
      // You can add more overrides as needed
    },
    palette: {
      primary: {
        main: 'rgb(18,82,166)',
      },
      background: {
        default: '#f5f5f5', // light gray background color
      },
    },
    typography: {
      fontFamily: '"Times New Roman", Times, serif',
    },
  });




  return (
    <ThemeProvider theme={defaultTheme}>
      <div className="page-container">

        <CssBaseline />
        <AppBar position="static">
          <Toolbar>
            <img src={logo} alt="Ministry of Education Logo" style={{ maxHeight: '70px', marginRight: '20px' }} />
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Ministry of Education
            </Typography>
            <Button color="inherit" onClick={() => navigate('/')}>Home</Button>
            <Button color="inherit" onClick={() => navigate('/registration')}>About</Button>
            <Button color="inherit" onClick={handleContactClick}>Contact</Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleContactClose}
            >
              <MenuItem onClick={handleContactClose}>
                <a href="https://t.me/MoENGAT" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Telegram</a>
              </MenuItem>
              <MenuItem onClick={handleContactClose}>
                <a href="https://chat.whatsapp.com/JSQtXWXvoYqLHj4gFvpouN" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>WhatsApp</a>
              </MenuItem>
            </Menu>
            <Button color="inherit" onClick={() => navigate('/login')}>Login</Button>


          </Toolbar>
        </AppBar>
        <Container component="main" sx={{ maxWidth: 'lg' }}>
          <CssBaseline />
          <Typography component="h1" variant="h5" align="center" sx={{ mt: 6 }}>
            National Graduate Admission Test (NGAT) Registration
          </Typography>
  

          <Typography component="h1" variant="h4" align="center" color="red" sx={{ mt: 6 }}>
            Registration closed 
          </Typography>

         
         

          

 
 <p component="h5" variant="h5" align="center" sx={{ mt: 2 }}>
            Download Registration guide <a href="https://ngat.ethernet.edu.et/file/ngat-registration-guide.pdf" >here (document)</a> <a href="https://youtu.be/PJZyswjcGgg"> or here ( Video )</a>
          </p>
          
          <Box mt={6} textAlign="center">


          </Box>
          <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Stepper activeStep={activeStep} sx={{ width: '100%', maxWidth: '900px', marginBottom: 3 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card sx={{ width: '100%', maxWidth: '900px' }}>
              <CardContent>
                <Typography component="h1" variant="h5" align="center">
                  {steps[activeStep]}
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                  {activeStep === 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          autoComplete="given-name"
                          name="firstName"
                          value={fields.firstName}
                          required
                          fullWidth
                          id="firstName"
                          label="First Name"
                          autoFocus
                          onChange={handleChange}
                          error={!!errors.firstName}
                          helperText={errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          id="fatherName"
                          label="Father Name"
                          name="fatherName"
                          value={fields.fatherName}
                          onChange={handleChange}
                          error={!!errors.fatherName}
                          helperText={errors.fatherName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          id="grandFatherName"
                          label="Grand Father Name"
                          name="grandFatherName"
                          value={fields.grandFatherName}
                          onChange={handleChange}
                          error={!!errors.grandFatherName}
                          helperText={errors.grandFatherName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Valid Email Address"
                          name="email"
                          autoComplete="email"
                          value={fields.email}
                          onChange={handleChange}
                          error={!!errors.email}
                          helperText={errors.email}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          id="phoneNumber"
                          label="Phone Number"
                          name="phoneNumber"
                          autoComplete="phone"
                          value={fields.phoneNumber}
                          onChange={handleChange}
                          error={!!errors.phoneNumber}
                          helperText={errors.phoneNumber}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <InputLabel id="gender-label">Gender</InputLabel>
                          <Select
                            labelId="gender-label"
                            id="gender"
                            name="gender"
                            value={fields.gender}
                            label="Gender"
                            onChange={handleChange}
                            error={!!errors.gender}
                            helperText={errors.gender}
                          >
                            <MenuItem value={'Male'}>Male</MenuItem>
                            <MenuItem value={'Female'}>Female</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type="file"
                          fullWidth
                          id="file"
                          name="file"
                          label="Upload profile picture here ( Passport size )"
                          InputLabelProps={{ shrink: true }}
                          onChange={handleChange}
                          error={!!errors.file}
                          helperText={errors.file}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 1 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="program-label">Apply for</InputLabel>
                          <Select
                            labelId="program-label"
                            id="program"
                            name="program"
                            value={selectedProgram}
                            label="Apply for"
                            onChange={handleProgramChange}
                            error={!!errors.program}
                            helperText={errors.program}
                          >
                            <MenuItem value={'Masters'}>Masters</MenuItem>
                            <MenuItem value={'PhD'}>PhD</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {selectedProgram === 'Masters' && (
                        <>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="speciality">Field of speciality applying for</InputLabel>
                              <Select
                                labelId="speciality-label"
                                id="speciality"
                                name="speciality"
                                value={fields.speciality}
                                label="Field of Speciality"
                                onChange={handleChange}
                                error={!!errors.speciality}
                                helperText={errors.speciality}
                              >
                                <MenuItem value="Engineering">Engineering</MenuItem>
                                <MenuItem value="Computational Sciences">Computational Sciences</MenuItem>
                                <MenuItem value="Life Sciences">Life Sciences</MenuItem>
                                <MenuItem value="Physical Sciences">Physical Sciences</MenuItem>
                                <MenuItem value="Medical and Health Sciences">Medical and Health Sciences</MenuItem>
                                <MenuItem value="Animal Sciences">Animal Sciences</MenuItem>
                                <MenuItem value="Plant Sciences">Plant Sciences</MenuItem>
                                <MenuItem value="Business and Economics">Business and Economics</MenuItem>
                                <MenuItem value="Educational Related Programs">Educational Related Programs</MenuItem>
                                <MenuItem value="Social Sciences">Social Sciences</MenuItem>
                                <MenuItem value="Humanities">Humanities</MenuItem>
                                <MenuItem value="Language, Folklore and Communication">Language, Folklore and Communication</MenuItem>
                                <MenuItem value="Military Sciences">Military Sciences</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id="bscProgramName"
                              name="bscProgramName"
                              label="BSc Program Name"
                              value={fields.bscProgramName}
                              onChange={handleChange}
                              error={!!errors.bscProgramName}
                              helperText={errors.bscProgramName}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id="bsccgpa"
                              name="bsccgpa"
                              label="Bsc CGPA"
                              value={fields.bsccgpa}
                              onChange={handleChange}
                              error={!!errors.bsccgpa}
                              helperText={errors.bsccgpa}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.institutionBsc}>
                              <InputLabel id="institution-label-bsc">BSc Institution</InputLabel>
                              <Select
                                labelId="institution-label-bsc"
                                id="institution-select-bsc"
                                value={fields.institutionBsc}
                                onChange={handleChange}
                                name="institutionBsc"
                                label="BSc Institution"
                              >
                                {institutions.map((institution) => (
                                  <MenuItem key={institution.id} value={institution.id}>
                                    {institution.name}
                                  </MenuItem>
                                ))}
                                <MenuItem value={'Other'}>Other</MenuItem>
                              </Select>
                              {errors.institutionBsc && (
                                <FormHelperText>{errors.institutionBsc}</FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          {fields.institutionBsc === 'Other' && (
                            <Grid item xs={12}>
                              <TextField
                                required
                                fullWidth
                                id="customInstitutionBsc"
                                name="customInstitutionBsc"
                                label="BSc Institution Name"
                                value={fields.customInstitutionBsc}
                                onChange={handleChange}
                                error={!!errors.customInstitutionBsc}
                                helperText={errors.customInstitutionBsc}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                      {selectedProgram === 'PhD' && (
                        <>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="speciality">Field of speciality applying for</InputLabel>
                              <Select
                                required
                                labelId="speciality-label"
                                id="speciality"
                                name="speciality"
                                value={fields.speciality}
                                label="Field of Speciality"
                                onChange={handleChange}
                                error={!!errors.speciality}
                                helperText={errors.speciality}
                              >
                                <MenuItem value="Engineering">Engineering</MenuItem>
                                <MenuItem value="Computational Sciences">Computational Sciences</MenuItem>
                                <MenuItem value="Life Sciences">Life Sciences</MenuItem>
                                <MenuItem value="Physical Sciences">Physical Sciences</MenuItem>
                                <MenuItem value="Medical and Health Sciences">Medical and Health Sciences</MenuItem>
                                <MenuItem value="Animal Sciences">Animal Sciences</MenuItem>
                                <MenuItem value="Plant Sciences">Plant Sciences</MenuItem>
                                <MenuItem value="Business and Economics">Business and Economics</MenuItem>
                                <MenuItem value="Educational Related Programs">Educational Related Programs</MenuItem>
                                <MenuItem value="Social Sciences">Social Sciences</MenuItem>
                                <MenuItem value="Humanities">Humanities</MenuItem>
                                <MenuItem value="Language, Folklore and Communication">Language, Folklore and Communication</MenuItem>
                                <MenuItem value="Military Sciences">Military Sciences</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id="bscProgramName"
                              name="bscProgramName"
                              label="BSc Program Name"
                              value={fields.bscProgramName}
                              onChange={handleChange}
                              error={!!errors.bscProgramName}
                              helperText={errors.bscProgramName}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id="bsccgpa"
                              name="bsccgpa"
                              label="Bsc CGPA"
                              value={fields.bsccgpa}
                              onChange={handleChange}
                              error={!!errors.bsccgpa}
                              helperText={errors.bsccgpa}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.institutionBsc}>
                              <InputLabel id="institution-label-bsc">BSc Institution</InputLabel>
                              <Select
                                labelId="institution-label-bsc"
                                id="institution-select-bsc"
                                value={fields.institutionBsc}
                                onChange={handleChange}
                                name="institutionBsc"
                                label="BSc Institution"
                              >
                                {institutions.map((institution) => (
                                  <MenuItem key={institution.id} value={institution.id}>
                                    {institution.name}
                                  </MenuItem>
                                ))}
                                <MenuItem value={'Other'}>Other</MenuItem>
                              </Select>
                              {errors.institutionBsc && (
                                <FormHelperText>{errors.institutionBsc}</FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          {fields.institutionBsc === 'Other' && (
                            <Grid item xs={12}>
                              <TextField
                                required
                                fullWidth
                                id="customInstitutionBsc"
                                name="customInstitutionBsc"
                                label="BSc Institution Name"
                                value={fields.customInstitutionBsc}
                                onChange={handleChange}
                                error={!!errors.customInstitutionBsc}
                                helperText={errors.customInstitutionBsc}
                              />
                            </Grid>
                          )}

                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              id="phdProgramName"
                              name="phdProgramName"
                              label="Msc Program Name"
                              value={fields.phdProgramName}
                              onChange={handleChange}
                              error={!!errors.phdProgramName}
                              helperText={errors.phdProgramName}
                            />
                          </Grid>
                          <Grid item xs={12}>

                            <TextField
                              required
                              fullWidth
                              id="masterscgpa"
                              name="masterscgpa"
                              label="Msc CGPA"
                              value={fields.masterscgpa}
                              onChange={handleChange}
                              error={!!errors.masterscgpa}
                              helperText={errors.masterscgpa}
                            />
                          </Grid>



                          <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.institutionMasters}>
                              <InputLabel id="institution-label-masters">Msc Institution</InputLabel>
                              <Select
                                labelId="institution-label-masters"
                                id="institution-select-masters"
                                value={fields.institutionMasters}
                                onChange={handleChange}
                                name="institutionMasters"
                                label="Msc Institution"
                              >
                                {institutions.map((institution) => (
                                  <MenuItem key={institution.id} value={institution.id}>
                                    {institution.name}
                                  </MenuItem>
                                ))}
                                <MenuItem value={'Other'}>Other</MenuItem>
                              </Select>
                              {errors.institutionMasters && (
                                <FormHelperText>{errors.institutionMasters}</FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          {fields.institutionMasters === 'Other' && (
                            <Grid item xs={12}>
                              <TextField
                                required
                                fullWidth
                                id="customInstitutionMasters"
                                name="customInstitutionMasters"
                                label="Msc Institution Name"
                                value={fields.customInstitutionMasters}
                                onChange={handleChange}
                                error={!!errors.customInstitutionMasters}
                                helperText={errors.customInstitutionMasters}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                      <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.examcenter}>
                          <InputLabel id="examcenter-label">Exam Center</InputLabel>
                          <Select
                            labelId="examcenter-label"
                            id="examcenter-select"
                            value={fields.examcenter}
                            onChange={handleChange}
                            name="examcenter"
                            label="Exam Center"
                          >
                            {examCenters.map((center) => (
                              <MenuItem key={center.id} value={center.id}>
                                {center.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.examcenter && (
                            <FormHelperText>{errors.examcenter}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={fields.consent}
                              onChange={e => setFields({ ...fields, consent: e.target.checked })}
                              name="consent"
                              color="primary"
                            />
                          }
                          label="I agree to the terms and conditions."
                        />
                        {!fields.consent && (
                          <FormHelperText error={true}>You must agree to the terms to proceed.</FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 2 && (
                    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Typography variant="h6" align="center" gutterBottom>
                        Please review your information before submitting
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ width: '70%' }}>
                          <List>
                            <ListItem>
                              <ListItemIcon>
                                <AccountCircleIcon color="primary" />
                              </ListItemIcon>
                              <ListItemText primary="First Name" secondary={fields.firstName} />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <AccountCircleIcon color="primary" />
                              </ListItemIcon>
                              <ListItemText primary="Father Name" secondary={fields.fatherName} />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <AccountCircleIcon color="primary" />
                              </ListItemIcon>
                              <ListItemText primary="Grand Father Name" secondary={fields.grandFatherName} />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <EmailIcon color="primary" />
                              </ListItemIcon>
                              <ListItemText primary="Email" secondary={fields.email} />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <PhoneIcon color="primary" />
                              </ListItemIcon>
                              <ListItemText primary="Phone Number" secondary={fields.phoneNumber} />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <GenderIcon color="primary" />
                              </ListItemIcon>
                              <ListItemText primary="Gender" secondary={fields.gender} />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <CheckCircleOutlineIcon color="primary" />
                              </ListItemIcon>
                              <ListItemText primary="Applying for" secondary={selectedProgram} />
                            </ListItem>
                            {selectedProgram && (
                              <>
                                <ListItem>
                                  <ListItemIcon>
                                    <SchoolIcon color="primary" />
                                  </ListItemIcon>
                                  <ListItemText primary="Field of Speciality" secondary={fields.speciality} />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <SchoolIcon color="primary" />
                                  </ListItemIcon>
                                  <ListItemText primary="BSc Program Name" secondary={fields.bscProgramName} />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <SchoolIcon color="primary" />
                                  </ListItemIcon>
                                  <ListItemText primary="BSc CGPA" secondary={fields.bsccgpa} />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <SchoolIcon color="primary" />
                                  </ListItemIcon>
                                  <ListItemText primary="BSc's Institution" secondary={fields.institutionBsc === 'Other' ? fields.customInstitutionBsc : (institutions.find(inst => inst.id === fields.institutionBsc)?.name || '')} />
                                </ListItem>
                                {selectedProgram === 'PhD' && (
                                  <>
                                    <ListItem>
                                      <ListItemIcon>
                                        <SchoolIcon color="primary" />
                                      </ListItemIcon>
                                      <ListItemText primary="Masters CGPA" secondary={fields.masterscgpa} />
                                    </ListItem>
                                    <ListItem>
                                      <ListItemIcon>
                                        <SchoolIcon color="primary" />
                                      </ListItemIcon>
                                      <ListItemText primary="PhD Program Name" secondary={fields.phdProgramName} />
                                    </ListItem>
                                    <ListItem>
                                      <ListItemIcon>
                                        <SchoolIcon color="primary" />
                                      </ListItemIcon>
                                      <ListItemText primary="Master's Institution" secondary={fields.institutionMasters === 'Other' ? fields.customInstitutionMasters : (institutions.find(inst => inst.id === fields.institutionMasters)?.name || '')} />
                                    </ListItem>
                                  </>
                                )}
                              </>
                            )}
                            <ListItem>
                              <ListItemIcon>
                                <SchoolIcon color="primary" />
                              </ListItemIcon>
                              <ListItemText primary="Exam Center" secondary={examCenters.find(center => center.id === fields.examcenter)?.name || ''} />
                            </ListItem>
                          </List>
                        </Box>
                        <Box sx={{ width: '30%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                          <Typography variant="subtitle1" sx={{ mb: 1 }}>Profile Picture</Typography>
                          {fields.file && (
                            <Box sx={{
                              border: `1px solid ${theme.palette.primary.main}`, // Use primary color from theme
                              borderRadius: '5px',
                              padding: 1
                            }}>
                              <img
                                src={URL.createObjectURL(fields.file)}
                                alt="Profile Photo"
                                style={{ maxWidth: '100%', maxHeight: '250px' }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        disabled={isUploading || isSubmitting} // Disable button while uploading or submitting
                      >
                        Submit
                      </Button>
                      {isUploading && (
                        <Box sx={{ width: '100%', mt: 1 }}>
                          <LinearProgress variant="determinate" value={uploadProgress} />
                        </Box>
                      )}
                      {isSubmitting && (
                        <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <CircularProgress />
                          <Typography sx={{ ml: 2 }}>Creating account activation link...</Typography>
                        </Box>
                      )}
                      {isSuccess && (
                        <Alert severity="success" sx={{ width: '100%', mt: 2 }}>
                          An account activation link has been sent to your email. Please follow the instructions there. It usually takes upto 5 - 10 minutes."
                        </Alert>
                      )}
                      {submitError && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                          {submitError}
                        </Alert>
                      )}
                    </Box>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    {activeStep < 2 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    ) : null}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>

                    
        </Container>

        <Footer></Footer>
      </div>
    </ThemeProvider>
  );
}
