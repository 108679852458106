import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, TextField, Button, Typography, Container, Link, Grid, CircularProgress, Snackbar, Alert, AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../logo.png'; // Ensure correct path
import Footer from '../pages/Footer'; // Ensure correct path to the Footer component
import './styles.css'; // Import the CSS file

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(18,82,166)',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: '"Times New Roman", Times, serif',
  },
});

const ResetPasswordForm = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
    const [isDisabled, setIsDisabled] = useState(false); // New state to control disabling of fields
    const navigate = useNavigate();

    const onBack = () => {
        navigate('/login'); // Adjust as needed
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setAlert({ open: true, message: 'Passwords do not match.', severity: 'error' });
            return;
        }
        setLoading(true);
        try {
            const response = await fetch('https://ngat.ethernet.edu.et/api/password-reset', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token, password, password_confirmation: confirmPassword }),
            });
            const data = await response.json();
            if (response.ok) {
                setAlert({ open: true, message: 'Password has been successfully reset. You can now login with your new password.', severity: 'success' });
                setIsDisabled(true); // Disable fields and button on success
                setTimeout(() => navigate('/login'), 6000); // Redirect after alert
            } else {
                setAlert({ open: true, message: data.message || 'Failed to reset password.', severity: 'error' });
            }
        } catch (error) {
            console.error('Error:', error);
            setAlert({ open: true, message: 'An error occurred.', severity: 'error' });
        }
        setLoading(false);
    };

    const handleCloseSnackbar = () => {
        setAlert({ ...alert, open: false });
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="page-container">
                <AppBar position="static">
                    <Toolbar>
                        <img src={logo} alt="Ministry of Education Logo" style={{ maxHeight: '50px', marginRight: '10px' }} />
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            Ministry of Education
                        </Typography>
                        <Button color="inherit" onClick={() => navigate('/')}>Home</Button>
                        <Button color="inherit" onClick={() => navigate('/about')}>About</Button>
                        <Button color="inherit" onClick={() => navigate('/contact')}>Contact</Button>
                        <Button color="inherit" onClick={() => navigate('/registration')}>Register</Button>
                        <Button color="inherit" onClick={() => navigate('/login')}>Login</Button>
                    </Toolbar>
                </AppBar>
                <Container component="main" maxWidth="xs">
                    <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography component="h1" variant="h5">Reset Password</Typography>
                        <Box component="form" noValidate onSubmit={handleResetPassword} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="New Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                disabled={isDisabled} // Disable field
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm New Password"
                                type="password"
                                id="confirmPassword"
                                autoComplete="new-password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                disabled={isDisabled} // Disable field
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={loading || isDisabled} // Disable button
                            >
                                {loading ? <CircularProgress size={24} /> : 'Reset Password'}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link variant="body2" onClick={onBack} style={{ cursor: 'pointer' }} disabled={isDisabled}>
                                        Back to login
                                    </Link>
                                </Grid>
                            </Grid>
                            {alert.open && (
                                <Box mt={2}>
                                    <Alert severity={alert.severity} onClose={handleCloseSnackbar}>{alert.message}</Alert>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Container>
                <Footer />
            </div>
        </ThemeProvider>
    );
};

export default ResetPasswordForm;
