import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Footer = () => {
    return (
        <footer>
            <Box
                sx={{
                    bgcolor: 'primary.main',
                    color: 'white',
                    textAlign: 'center',
                    py: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', sm: 'row' }, // Stack icons on top of text on small screens
                    gap: 2 // Space between text and icons
                }}
            >
                <Typography variant="body2">
                    © 2024 Ministry of Education Ethiopia. All rights reserved.
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                        color="inherit"
                        component="a"
                        href="https://t.me/MoENGAT"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <TelegramIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        component="a"
                        href="https://chat.whatsapp.com/JSQtXWXvoYqLHj4gFvpouN"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <WhatsAppIcon />
                    </IconButton>
                </Box>
            </Box>
        </footer>
    );
};

export default Footer;
