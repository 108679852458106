import React, { createContext, useContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [authStatus, setAuthStatus] = useState(() => !!localStorage.getItem('authToken'));

    const login = () => {
        setAuthStatus(true);
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        setAuthStatus(false);
    };

    const updateAuthStatus = (status) => {
        setAuthStatus(status);
    };

    useEffect(() => {
        const handleStorageChange = () => {
            updateAuthStatus(!!localStorage.getItem('authToken'));
        };
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, [updateAuthStatus]);

    return (
        <AuthContext.Provider value={{ authStatus, login, logout, updateAuthStatus }}>
            {children}
        </AuthContext.Provider>
    );
};
